import React from 'react'
import "../css/gif_image.css";

export default function GifImage({ data }) {
    const baseUrl = "https://api.onit.to/uploads";

    // convert radian to degree
    const radianToDegree = (radian) => {
        return radian * (180 / Math.PI);
    }
    const angle = radianToDegree(data['angle']);

    document.documentElement.style.setProperty("--gif-height", `${data['height']}px`);
    document.documentElement.style.setProperty("--gif-width", `${data['width']}px`);
    document.documentElement.style.setProperty("--gif-top", `${data['top']}px`);
    document.documentElement.style.setProperty("--gif-left", `${data['left']}px`);
    document.documentElement.style.setProperty("--gif-angle", `${angle}deg`);

    return (
        data['source'] !== "" ?
            <img className='gifImg' src={`${data['source']}`} /> : ""

    )
}
