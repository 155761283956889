import React from 'react'
import '../css/viewBox.css';
import layer from "../assets/layer.png";
import layer1 from "../assets/layer1.png";
import layer2 from "../assets/layer2.png";
import layer3 from "../assets/layer3.png";
import layer4 from "../assets/layer4.png";
import layer5 from "../assets/layer5.png";
import layer6 from "../assets/layer6.png";
import layer7 from "../assets/layer7.png";
import layer8 from "../assets/layer8.png";

export default function ViewBox({ frame }) {

  const getFrame = () => {
    switch (frame) {
      case "m":
        return layer;
      case "s":
        return layer1;
      case "s1":
        return layer2;
      case "s2":
        return layer3;
      case "s3":
        return layer4;
      case "s4":
        return layer5;
      case "s5":
        return layer6;
      case "s6":
        return layer7;
      case "s7":
        return layer8;
      default:
        return "";
    }
  }

  return (
    frame !== "" ?
      <img className='view-box' src={getFrame()}></img> : ""
  )
}
