import React from 'react'
import "../css/source_image.css";

export default function SourceImage({ data }) {
    const baseUrl = "https://api.onit.to/uploads";

    // convert radian to degree
    const radianToDegree = (radian) => {
        return radian * (180 / Math.PI);
    }

    const angle = radianToDegree(data['angle']);
    
    document.documentElement.style.setProperty("--img-height", `${data['height']}px`);
    document.documentElement.style.setProperty("--img-width", `${data['width']}px`);
    document.documentElement.style.setProperty("--img-top", `${data['top']}px`);
    document.documentElement.style.setProperty("--img-left", `${data['left']}px`);
    document.documentElement.style.setProperty("--img-angle", `${angle}deg`);


    return (
        data['source'] !== "" ?
            <img className='img' src={`${baseUrl}/${data['source']}`} /> : ""
    )
}
