import Preview from './pages/preview';
import Home from './pages/home';
import Page404 from './pages/404';
import Combined from './pages/combined';
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import RedirectView from './componants/redirect_view';
import Login from "./pages/login";
import TTS from './pages/tts';
import GoogleTts from './pages/google_tts';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    ReactGA.initialize('G-1MCN2F4ECE');
    ReactGA.pageview(window.location.pathname + window.location.hash);
  }, []);

  return (
    <Router>
      <Routes>
        <Route path='/' element={<RedirectView />} />
        <Route path='/Home' element={<Home />} />
        <Route path='/Home/Combined' element={<Combined />} />
        <Route path='/Home/TTS' element={<TTS />} />
        <Route path='/Home/Dws' element={<GoogleTts />} />
        <Route path='/Preview' element={<Preview />} />
        <Route path='/Login' element={<Login />} />
        <Route path='*' element={<Page404 />} />
      </Routes>
    </Router>
  );
}

export default App;
