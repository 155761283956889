import '../css/home.css';
import Header from '../componants/header';
import ViewBox from '../componants/view_box.js';
import CameraButton from '../componants/camera_button';
import Camera from '../componants/camera'
import { useRef, useMemo, createRef } from "react";
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { css } from "@emotion/react";
import { useAlert } from 'react-alert'
import error from '../assets/error1.png';
import { useScreenshot } from 'use-react-screenshot'
import { useSpeechSynthesis } from 'react-speech-kit';
import Modal from "react-modal";

function TTS() {
    const baseUrl = "https://api.onit.to/api";
    const alert = useAlert();
    const webRef = useRef(null);
    const ref = createRef(null);
    let navigate = useNavigate();
    const [imageData, setImageData] = useState(1);
    const [loading, setLoading] = useState(false);
    const [showError, setError] = useState(false);
    const [img, takeScreenshot] = useScreenshot({ type: "image/jpeg", quality: 0.8 });
    const { speak } = useSpeechSynthesis();
    const [isOpen, setIsOpen] = useState(false);
    const [Tags, setTags] = useState([]);

    function useQuery() {
        const { search } = useLocation();
        return useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    let id = query.get("id");
    let isKids = query.get("kidTheme") ?? "false";
    let frame = query.get("frame") ?? "m";

    const override = css`   
    position: absolute;
    top: 50%;
    left: 45%;
    display: inline-block;
    `;

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    // const getImage = async () => {
    //     const response = await fetch(`${baseUrl}/detection/getById/${id}`);
    //     const data = await response.json();
    //     setImageData(data['data']);
    // }

    useEffect(() => {
        // if (imageData === 1) {
        //     getImage();
        // }
        if (img) {
            takePicture(img);
        }
    }, [imageData, img]);


    function DataURIToBlob(dataURI) {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
    }

    const recognizeImage = async (image) => {
        const formData = new FormData();

        const file = DataURIToBlob(image);
        formData.append("image", file);
        const response = await fetch(`${baseUrl}/detection/text`, {
            method: 'POST',
            body: formData
        });
        let tags = [];
        const d = await response.json();
        const texts = d['Text'];
        texts.map((text) => {
            tags.push(text);
        });
        return tags;
    }

    const captureScreenshot = async () => {
        takeScreenshot(ref.current);
    }

    const takePicture = async (image) => {
        setLoading(true);

        var tags = await recognizeImage(image);
        setTags(tags);
        setLoading(false);
        toggleModal();
    }

    const customStyles = {
        content: {
            backgroundColor: 'grey',
            padding: '10px',
            height: "100%"
        },
    };

    const talk = (text) => {
        speak({ text: text });
    }

    return (
        <div className="App">
            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="Speech"
                style={customStyles}
                ariaHideApp={false}
            >
                <div className='tags-container'>
                    {Tags.map((comp, i) => <div onClick={() => talk(comp)} className='tag-box'>{comp}</div>)}
                </div>

                <button className='close-btn' onClick={toggleModal}>CLOSE</button>
            </Modal>

            <div ref={ref}>
                <Camera webRef={webRef} />
                <ViewBox frame={frame} />
            </div>

            {showError === true ? <img className='error' src={error}></img> : ""}
            <Header username={imageData === 1 ? "Word Reader" : imageData['user']} />
            <ScaleLoader css={override} loading={loading} color="yellow" />
            {/* <InstructionBox isKid={isKids} instruction={imageData === 1 ? "" : imageData['msg']} /> */}
            <CameraButton isKid={isKids} takePicture={() => captureScreenshot()} />

        </div>
    );
}

export default TTS;
