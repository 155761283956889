import '../css/home.css';
import Header from '../componants/header';
import InstructionBox from '../componants/instruction_box';
import ViewBox from '../componants/view_box.js';
import CameraButton from '../componants/camera_button';
import Camera from '../componants/camera'
import { useRef, useMemo, createRef } from "react";
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { css } from "@emotion/react";
import { useAlert } from 'react-alert'
import error from '../assets/error1.png';
import { useScreenshot } from 'use-react-screenshot'
import Footer from "../componants/footer";
import blibliLogo from '../assets/bliblilogo.png';
import defaultPopup from '../assets/defaultpop.png';
import ReactGA from 'react-ga4';
import Modal from "react-modal";
import { isLabelWithInternallyDisabledControl } from '@testing-library/user-event/dist/utils';

function Home() {
    const baseUrl = "https://api.onit.to/api";
    const alert = useAlert();
    const webRef = useRef(null);
    const ref = createRef(null);
    let navigate = useNavigate();
    const [imageData, setImageData] = useState(1);
    const [loading, setLoading] = useState(false);
    const [showError, setError] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [cameraOpen, setcameraOpen] = useState(false);
    const [img, takeScreenshot] = useScreenshot({ type: "image/jpeg", quality: 0.8 });


    function toggleModal() {
        setIsOpen(!isOpen);
    }

    function useQuery() {
        const { search } = useLocation();
        return useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    let id = query.get("id");
    let isKids = query.get("kidTheme") ?? "false";
    let frame = query.get("frame") ?? "";
    let camera = query.get("camera") ?? "back";
    let msg = query.get("msg") ?? "";
    let logo = query.get("logo") ?? "false";
    let object = query.get("object") ?? "t";
    let logoo = query.get("logoo") ?? "t";
    let text = query.get("text") ?? "t";
    let label = query.get("label") ?? "t";

    const override = css`   
    position: absolute;
    top: 50%;
    left: 45%;
    display: inline-block;
    `;

    const getImage = async () => {
        const response = await fetch(`${baseUrl}/detection/getById/${id}`);
        const data = await response.json();
        setImageData(data['data']);
    }

    useEffect(() => {
        document.title = window.location.pathname + window.location.hash;
        if (imageData === 1) {
            getImage();
        }
        if (img) {
            takePicture(img);
        }
        // setFirstTime();
    }, [imageData, img]);


    function DataURIToBlob(dataURI) {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
    }

    const recognizeImage = async (image) => {
        const formData = new FormData();

        const file = DataURIToBlob(image);
        formData.append("image", file);
        const response = await fetch(`${baseUrl}/detection?object=${object}&logo=${logoo}&text=${text}&label=${label}`, {
            method: 'POST',
            body: formData
        });
        let tags = [];
        const d = await response.json();
        const labels = d['Labels'];
        const logos = d['Logos'];
        const texts = d['Text'];

        labels.map((label) => {
            tags.push(label);
        });
        logos.map((logo) => {
            tags.push(logo);
        });
        texts.map((text) => {
            tags.push(text);
        });
        return tags;
    }

    const captureScreenshot = async () => {
        takeScreenshot(ref.current);
    }

    const takePicture = async (image) => {

        eventTrack("take_picture", "camera_click", window.location.hash);
        setLoading(true);
        // let image = webRef.current.getScreenshot();

        if (imageData !== 1) {
            const imgTag = imageData['tag'];

            var tags = await recognizeImage(image);

            const re = new RegExp(`${imgTag}`);
            let isMatch = false;
            tags.map((tag) => {
                var test = re.test(tag.toLowerCase());
                if (test) {
                    isMatch = true;
                    return;
                }
            });

            if (isMatch) {
                navigate(`/Preview?kidTheme=${isKids}&msg=${msg}`, { state: { image: image, imgData: imageData, hash: window.location.hash } });
            } else {
                eventTrack("Detection", "failed_detection", window.location.hash);
                if (isKids === "false") {
                    alert.error("Oops! we couldn't recognize this object.");
                } else {
                    setError(true);
                    setTimeout(() => {
                        setError(false);
                    }, 5000);
                }
            }
            setLoading(false);
        }
    }


    const eventTrack = (category, action, label) => {
        ReactGA.event({
            category: category,
            action: action,
            label: label,
        });
    }


    const setFirstTime = () => {
        var firstTime = localStorage.getItem("first_time") ?? "false";
        if (firstTime === "false") {
            toggleModal();
            localStorage.setItem("first_time", "true");
        }
    }

    const openCamera = () => {
        localStorage.setItem("camera_open", "true");
        toggleModal();
        window.location.reload();
    }

    return (
        <div className="App">
            <Modal
                isOpen={isOpen}
                className="modal"
                onRequestClose={() => { toggleModal(); }}
                contentLabel="Popup"
                ariaHideApp={false}
            >
                <img onClick={() => { openCamera(); }} className='popup-img' src={defaultPopup} />
            </Modal>


            <div ref={ref}>
                <Camera isOpen={localStorage.getItem("camera_open") ?? "false"} webRef={webRef} camera={camera} />
                <ViewBox frame={frame} />
                {logo === "true" ? <img className='blibli-logo' src={blibliLogo} /> : ""}
            </div>


            {showError === true ? <img className='error' src={error}></img> : ""}
            <Header username={imageData === 1 ? "" : imageData['user']} />
            <ScaleLoader css={override} loading={loading} color="yellow" />
            <InstructionBox isKid={isKids} instruction={imageData === 1 ? "" : imageData['msg']} />
            <CameraButton isKid={isKids} takePicture={() => captureScreenshot()} />
            <Footer msg={msg} type="" />
        </div>
    );
}

export default Home;
