import "../css/login.css";
import logo from "../assets/loginlogo.png"
import bg from "../assets/loginbg.png";
import googleBtn from "../assets/googleclick.png";

import { GoogleLoginButton } from "react-social-login-buttons";
import {
    LoginSocialGoogle,
} from 'reactjs-social-login'
import React, { useCallback, useRef, useState } from 'react'

function Login() {
    const url = "https://api.onit.to/api";

    const googleRef = useRef(null);
    let [isAgree, setAgree] = useState(false);


    const onLoginStart = useCallback(async () => {
        console.log('login start');
    }, [])

    const onLogoutFailure = useCallback(() => {
        console.log('logout fail')
    }, [])

    const onLogoutSuccess = useCallback(() => {
        console.log('logout success');
    }, [])

    const registerUser = async (email, fullName) => {
        let reqBody = {
            'email': email,
            'username': fullName,
            'pin': fullName,
            'date': Date.now(),
            'token': "",
        };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(reqBody)
        };

        const response = await fetch(`${url}/user`, requestOptions);
        const body = await response.json();
        if (body.success === 1) {
            window.location.replace('https://gosimu.com/welcome.html');
        }
    }

    return (
        <div className="login-container">
            <img className="bg-img" src={bg} />
            <img className="logo" src={logo} />
            <label className="checkbox">
                <input type="checkbox" defaultChecked={isAgree} onChange={() => { setAgree(!isAgree) }} />
                I agree to terms and conditions listed  <a href="https://gosimu.com/privacy/PrivacyPolicyOnitLite.txt" target="_blank">here </a>
            </label>
            {isAgree ? <LoginSocialGoogle
                ref={googleRef}
                client_id={'360820611056-1s3k4emhq0fr5o7q59mg0l01dse5d29o.apps.googleusercontent.com'}
                onLogoutFailure={onLogoutFailure}
                onLoginStart={onLoginStart}
                onLogoutSuccess={onLogoutSuccess}
                onResolve={async ({ provider, data }) => {
                    const email = data.email;
                    const firstname = data.firstName;
                    const lastname = data.lastName;
                    const response = await fetch(`${url}/user/withEmail/${email}`);
                    const body = await response.json();
                    if (body.success === 1) {
                        console.log(body.data);
                        window.location.replace('https://gosimu.com/welcome.html');
                    } else {
                        registerUser(email, `${firstname}${lastname}`);
                    }
                }}
                onReject={(err) => {
                    console.log(err)
                }}
            >
                <img className="google-btn" src={googleBtn} />
            </LoginSocialGoogle> : <img className="google-btn" src={googleBtn} />}

        </div>
    )
}

export default Login