import React from 'react'
import "../css/footer.css";

export default function Footer(props) {
    return (
        props.type == "preview" ?
            <div className='footer'>
                <div className='spacer'></div>

                <div className='msg-text'>{props.msg}
                </div>

                <div className='spacer'></div>
            </div> : <div className='footer'>
                <div className='spacer'></div>


                <div className='msg-text'>{props.msg}
                </div>

                <div className='spacer'></div>
            </div>
    )
}
