import Webcam from 'react-webcam';
import "../css/camera.css";

export default function Camera(props) {
    const videoConstraints = {
        width: 1920,
        height: 1080,
        facingMode: props.camera == "back" ? "environment" : "user",
        zoom: 2.0,
    };

    if (props.camera == "front") {
        document.documentElement.style.setProperty("--webkit-transform", `scaleX(-1)`);
        document.documentElement.style.setProperty("--transform", `scaleX(-1)`);
    }

    return (
        <div >
            <Webcam ref={props.webRef} className='camera' videoConstraints={videoConstraints} />
        </div>
    )
}
