import '../css/home.css';
import Header from '../componants/header';
import ViewBox from '../componants/view_box.js';
import CameraButton from '../componants/camera_button';
import Camera from '../componants/camera'
import { useRef, useMemo, createRef } from "react";
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { css } from "@emotion/react";
import { useAlert } from 'react-alert'
import error from '../assets/error1.png';
import { useScreenshot } from 'use-react-screenshot'
import { useSpeechSynthesis } from 'react-speech-kit';
import Modal from "react-modal";
import volumeIcon from "../assets/volume.png";
import historyIcon from "../assets/history.png";
import Popup from 'reactjs-popup';

function GoogleTts() {
    const baseUrl = "https://api.onit.to/api";
    const alert = useAlert();
    const webRef = useRef(null);
    const ref = createRef(null);
    let navigate = useNavigate();
    const [imageData, setImageData] = useState(1);
    const [loading, setLoading] = useState(false);
    const [showError, setError] = useState(false);
    const [img, takeScreenshot] = useScreenshot({ type: "image/jpeg", quality: 0.8 });
    const { speak } = useSpeechSynthesis();
    const [isOpen, setIsOpen] = useState(false);
    const [sort, setSort] = useState(false);
    const [showSort, setShowSort] = useState(false);
    const [popup, setPopup] = useState(false);
    const [Tags, setTags] = useState([]);
    const [text, setText] = useState("");
    const [selectedId, setSelectedId] = useState(0);

    function useQuery() {
        const { search } = useLocation();
        return useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    let id = query.get("id");
    let isKids = query.get("kidTheme") ?? "false";
    let frame = query.get("frame") ?? "";

    const override = css`   
    position: absolute;
    top: 50%;
    left: 45%;
    display: inline-block;
    `;

    function toggleModal(value) {
        setIsOpen(!isOpen);
        setShowSort(value);
    }

    function togglePopup() {
        setPopup(!popup);
    }

    // const getImage = async () => {
    //     const response = await fetch(`${baseUrl}/detection/getById/${id}`);
    //     const data = await response.json();
    //     setImageData(data['data']);
    // }

    useEffect(() => {
        // if (imageData === 1) {
        //     getImage();
        // }
        if (img) {
            takePicture(img);
        }
        if (sort === true) {
            var history = localStorage.getItem("history") ?? "[]";
            var parsedList = JSON.parse(history);
            parsedList.sort((a, b) => (a.tag > b.tag ? 1 : 1));
            setTags(parsedList);
        } else {
            var history = localStorage.getItem("history") ?? "[]";
            var parsedList = JSON.parse(history);
            parsedList.sort((a, b) => (a.tag > b.tag ? -1 : 1));
            setTags(parsedList);
        }
    }, [imageData, img, sort]);


    function DataURIToBlob(dataURI) {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
    }

    const recognizeImage = async (image) => {
        const formData = new FormData();

        const file = DataURIToBlob(image);
        formData.append("image", file);
        const response = await fetch(`${baseUrl}/detection/text`, {
            method: 'POST',
            body: formData
        });
        let tags = [];
        const d = await response.json();
        const texts = d['Text'];
        texts.map((text) => {
            tags.push(text);
        });
        return tags;
    }

    const captureScreenshot = async () => {
        takeScreenshot(ref.current);
    }

    const takePicture = async (image) => {
        setLoading(true);
        if (text === "") {
            var tags = await recognizeImage(image);
            // var items = [
            //     { id: 1, tag: "Ab" }, { id: 2, tag: "bc" }, { id: 3, tag: "cd" }, { id: 4, tag: "df" },
            // ];
            var items = [];
            var idCount = 1;
            tags.forEach((tag) => {
                var item = { id: idCount, tag: tag };
                items.push(item);
                idCount++;
            });
            setTags(items);
            toggleModal(false);
        } else {
            talk(text, 0);
        }
        setLoading(false);

    }

    const customStyles = {
        content: {
            backgroundColor: '#E9FFFF',
            padding: '10px',
            // overflow: "auto",
            borderRadius: "10px",
            border: "none",
            // top: "40%",
        },
    };


    const talk = async (text, id) => {
        setSelectedId(id)
        const url = "https://api.onit.to/api/detection/textToSpeech";
        const data = { "text": text }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        const res = await fetch(url, requestOptions);
        const json = await res.json();
        let audio = new Audio("https://api.onit.to/uploads/" + json.filename);
        audio.play();
        saveToHistory(text);
        setTimeout(() => {
            setSelectedId(0);
        }, 5000);
    }

    function saveToHistory(text) {
        var history = localStorage.getItem("history") ?? "[]";
        var parsedList = JSON.parse(history);

        for (let i = 0; i < parsedList.length; i++) {
            const e = parsedList[i];
            if (e.tag === text) {
                return;
            }
        }
        if (parsedList.length === 14) {
            parsedList = [];
        }
        var id = 1;
        if (parsedList.length !== 0) {
            id = parsedList[parsedList.length - 1].id + 1;
        }
        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
        var item = { id: id, tag: text, date: date };
        parsedList.push(item);
        localStorage.setItem("history", JSON.stringify(parsedList));
    }

    function handleInput(e) {
        const text = e.target.value;
        setText(text);
    }

    function openHistory() {
        togglePopup();
        var history = localStorage.getItem("history") ?? "[]";
        var parsedList = JSON.parse(history);
        parsedList.reverse();
        setTags(parsedList);
        toggleModal(true);
    }

    function toggleSort() {
        setSort(!sort);
    }

    function signout() {
        togglePopup();
        window.location.replace('https://www.onapp.life/login');
    }

    return (
        <div className="App">
            <Modal
                isOpen={isOpen}
                onRequestClose={() => { toggleModal(false); }}
                contentLabel="Speech"
                style={customStyles}
                ariaHideApp={false}
            >
                <div className='tags-container'>
                    {showSort === false ? "" : <h3 className='header-txt' >History</h3>}
                    {showSort === false ? "" : <button className={sort == true ? 'sort-enable' : 'sort'} onClick={toggleSort}>A-Z</button>}
                    {Tags.map(({ id, tag, date }) =>
                        <div className='date-col'>
                            <div className='date-text'>{date}</div>
                            <div
                                key={id}
                                onClick={() => talk(tag, id)}
                                className={selectedId === id ? 'tag-box-taped' : 'tag-box'}
                            >
                                {tag}
                                <img className='volume-icon' src={volumeIcon} />
                            </div>
                        </div>
                    )}
                </div>
                <div className='spacer' />
                <button className='close-btn' onClick={() => { toggleModal(false); }}>CLOSE</button>
            </Modal>

            <div ref={ref}>
                <Camera webRef={webRef} />
                <ViewBox frame={frame} />
            </div>
            <Popup open={popup} onOpen={togglePopup} arrow={false} offsetX={10} offsetY={10} trigger={<img className='history-icon' src={historyIcon} />} position="bottom left">
                <div className='popup-menu'>
                    <div className='popup-item' onClick={openHistory}>History</div>
                    <div className='popup-item' onClick={signout}>Signout</div>
                </div>
            </Popup>

            

            <input className='text-field' name='name' type="text" onChange={handleInput} />

            {showError === true ? <img className='error' src={error}></img> : ""}
            <Header username={imageData === 1 ? "Word Reader" : imageData['user']} />
            <ScaleLoader css={override} loading={loading} color="yellow" />
            {/* <InstructionBox isKid={isKids} instruction={imageData === 1 ? "" : imageData['msg']} /> */}
            <CameraButton isKid={isKids} takePicture={() => captureScreenshot()} />

        </div>
    );
}

export default GoogleTts;
