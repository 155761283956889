import React, { useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../css/preview.css";
import Header from "../componants/header";
import Footer from "../componants/footer";
import SourceImage from "../componants/source_image";
import DrawingImage from "../componants/drawing_image";
import GifImage from "../componants/gif_image";
import RandomText from "../componants/random_text";
import WebviewSource from "../componants/webview_source";
import cameraback from "../assets/cameraback.png";
import share from "../assets/share.png";
import { ShareSocial } from 'react-share-social'
import Modal from "react-modal";
import ReactGA from 'react-ga4';

function Preview() {
    const baseUrl = "https://api.onit.to/api";
    const location = useLocation();
    let bgImage = location.state.image;
    let data = location.state.imgData;
    let hash = location.state.hash;
    const [isOpen, setIsOpen] = useState(false);

    // imageData
    let imgSource = data['img_source'];
    let imgHeight = data['img_height'];
    let imgWidth = data['img_width'];
    let imgLeft = data['img_left'];
    let imgTop = data['img_top'];
    let imgAngle = data['angle'];
    // img_source
    let img = { source: imgSource, height: imgHeight, width: imgWidth, left: imgLeft, top: imgTop, angle: imgAngle };

    // DrawaingData
    let drawingSource = data['drawingSource'];

    // gifData
    let gifSource = data['filter_Id'];
    let gifHeight = data['gHeight'];
    let gifWidth = data['gWidth'];
    let gifLeft = data['gLeft'];
    let gifTop = data['gTop'];
    let gifAngle = data['gAngle'];
    // img_source
    let gif = { source: gifSource, height: gifHeight, width: gifWidth, left: gifLeft, top: gifTop, angle: gifAngle };

    // webviewData
    let webSource = data['siteUrl'];
    let webHeight = data['wHeight'];
    let webWidth = data['wWidth'];
    let webLeft = data['wLeft'];
    let webTop = data['wTop'];
    let webAngle = data['wAngle'];
    // webview_source
    let webview = { source: webSource, height: webHeight, width: webWidth, left: webLeft, top: webTop, angle: webAngle };


    //Random Text Data
    let isRandom = data['isRandom'];
    let [randomText, setRandomText] = useState(data['text']);
    let tag = data['tag'];

    function toggleModal() {
        setIsOpen(!isOpen);
        console.log("Share CLICKED");
    }

    const getRandomText = async () => {
        const response = await fetch(`${baseUrl}/text/${tag}`);
        const data = await response.json();
        if (data['success'] === 1) {
            const texts = [];
            data['data'].map((t) => {
                texts.push(t['text']);
            });
            var rText = texts[Math.floor(Math.random() * texts.length)];
            setRandomText(rText);
        }

    }

    if (isRandom === 1 && randomText === "random") {
        getRandomText();
    }

    const updateVisitCount = async () => {
        const response = await fetch(`${baseUrl}/analytics`, { method: 'PUT' });
        const data = await response.json();

    }

    var imageId = data['image_Id'];
    const updateImageVisitCount = async () => {
        const response = await fetch(`${baseUrl}/detection/update/visit/${imageId}`, { method: 'PUT', });
        const data = await response.json();
    }

    updateVisitCount();
    setTimeout(() => {
        updateImageVisitCount();
    }, 3000);

    let navigate = useNavigate();

    function useQuery() {
        const { search } = useLocation();
        return useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    let isKids = query.get("kidTheme") ?? false;
    let msg = query.get("msg") ?? "";

    const customStyles = {
        content: {
            backgroundColor: '#E9FFFF',
            padding: '0',
            borderRadius: "10px",
            border: "none",
            height: "max-content",
        },
    };

    const style = {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        padding: '10px',
    };

    const eventTrack = (category, action, label) => {
        ReactGA.event({
            category: category,
            action: action,
            label: label,
        });
    }

    eventTrack("Detection", data['tag'], hash);

    return (
        <div className="main">
            <img className="bg" src={bgImage}></img>
            <Header username={data['user']} />
            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="Share"
                style={customStyles}
                ariaHideApp={false}
            >
                <ShareSocial
                    style={style}
                    url={`https://onit.to/${hash}`}
                    socialTypes={['facebook', 'twitter', 'line', 'linkedin', 'reddit', 'hatena', 'instapaper', 'livejournal', 'mailru', 'ok', 'pinterest']}
                />
            </Modal>
            <RandomText text={randomText} />
            <SourceImage data={img} />
            <GifImage data={gif} />
            <WebviewSource data={webview} />
            <DrawingImage data={drawingSource} />
            <img onClick={() => { navigate(-1) }} className="back-button" src={cameraback}></img>

            <img className="share-btn" onClick={toggleModal} src={share} />

            <Footer msg={msg} type="preview" />
        </div>
    );
}

export default Preview;
