import React from 'react'
import "../css/header.css";

export default function Header(props) {
  return (
    <div className='header'>
        <div className='spacing'></div>
        <div>From: {props.username}</div>
        <div className='spacing'></div>
    </div>
  )
}
