import React from 'react'
import "../css/webview_source.css";

export default function WebviewSource({ data }) {
    console.log(data)

    // convert radian to degree
    const radianToDegree = (radian) => {
        return radian * (180 / Math.PI);
    }

    const angle = radianToDegree(data['angle']);
    document.documentElement.style.setProperty("--web-height", `${data['height']}px`);
    document.documentElement.style.setProperty("--web-width", `${data['width']}px`);
    document.documentElement.style.setProperty("--web-top", `${data['top']}px`);
    document.documentElement.style.setProperty("--web-left", `${data['left']}px`);
    document.documentElement.style.setProperty("--web-angle", `${angle}deg`);


    return (
        data['source'] !== "" ?
            <embed className='web-img' src={data['source']} /> : ""
    )
}
