import React from 'react'
import "../css/drawing_image.css";

export default function DrawingImage({ data }) {
    const baseUrl = "https://api.onit.to/uploads";

    return (
        data !== ""?
        <img className='drawingImg' src={`${baseUrl}/${data}`} />
        : ""
    )
}
