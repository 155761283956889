import React from 'react'
import '../css/button.css'
import cameraButton from "../assets/cameraclick.png";

export default function CameraButton({ takePicture, isKid }) {
    return (
        <div onClick={() => takePicture()} className='container'>
            {isKid === "false" ?
                <div className='outerCircle'>
                    <div className='innerCircle'></div>
                </div>
                : <img className='img-button' src={cameraButton}></img>
            }
        </div>
    )
}
