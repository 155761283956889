import React from 'react'
import '../css/instructionbox.css'

export default function InstructionBox(props) {
    
    return (
        props.isKid === "false" ?
            <div className='box'>
                <div className='text'>
                    {props.instruction}
                </div>
            </div>
            : <div className='kid-box'>
                <div className='text'>
                    {props.instruction}
                </div>
            </div>
    )
}
