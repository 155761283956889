import React from 'react'
import "../css/random_text.css";

export default function RandomText({ text }) {
    return (
        text !== "" ?
            <div className='random_main'>
                <div className='random_text'>{text}</div>
            </div> :
            ""
    )
}
